import {useState} from 'react';
import {Modal, Input} from 'reactstrap';
import SwipeButton from '../SwipeButton';
import {PointS, Quote} from '../Misc';

function Contacts(props) {
    const [modal, setModal] = useState(false);
    const [myname, setMyName] = useState("");
    const [myphone, setMyPhone] = useState("");
    const [email, setEmail] = useState("");
    const [thank, setThank] = useState("");
    const [discl, setDiscl] = useState(0);

    const handleClose = () => setModal(false);
    const handleShow = () => {
        setModal(true);
    }

    const send_details = () => {
      const apiName = 'apicontacts'; // replace this with your api name.
      const path = '/contacts'; //replace this with the path you have configured on your API
      const myInit = {
          body: {
            "name": myname,
            "phone": myphone,
            "email": email
          }, // replace this with attributes you need
          headers: { 'Content-Type': 'application/json' }, // OPTIONAL
      };

      var API = {};

      API
        .post(apiName, path, myInit)
        .then(response => {
          //data well received
          setThank({
            'en': "Thank you! You can close this. We will contact you shortly!",
            'ru': "Спасибо, данные отправлены! Мы с Вами свяжемся в скором времени!",
            'de': "Vielen Dank! Wir werden Sie in Kürze kontaktieren."
          }[props.lang]);
        })
        .catch(error => {
          console.log(error.response);
        });
      //const requestOptions = {
      //    method: 'POST',
      //    headers: { 'Content-Type': 'application/json' },
      //    body: JSON.stringify({
      //      "name": myname,
      //      "phone": myphone,
      //      "email": email
      //    })
      //};
      //fetch('https://blackfort.io/contact', requestOptions).then(
      //(response)=>response.json()).then((data)=>{
      //    //data well received
      //    setThank({
      //      'en': "Thank you! You can close this. We will contact you shortly!",
      //      'ru': "Спасибо, данные отправлены! Мы с Вами свяжемся в скором времени!",
      //      'de': "Vielen Dank! Wir werden Sie in Kürze kontaktieren."
      //    }[props.lang]);
      //});
    }
  
    return (
      <>
        <SwipeButton lang={props.lang} onClick={handleShow} />
  
        <Modal
          isOpen={modal}
          size="lg"
          contentClassName="tw-rounded-lg"
          key="contact"
          id="contact"
        >
          <div className="tw-flex tw-flex-row tw-justify-end tw-w-full tw-h-5">
            <small id="emailHelp" className="form-text text-muted tw-pr-2 tw-cursor-pointer" onClick={handleClose}>{{
              'en': 'Close',
              'ru': 'Закрыть',
              'de': 'gehen Sie zurück'
            }[props.lang]}</small>
          </div>

          <div className="tw-flex tw-flex-col tw-items-center tw-pb-8 tw-pl-8 tw-pr-8 ">
          <h1 className="tw-text-4xl tw-font-bold tw-text-center tw-my-4" style={{
            borderBottom: "1px solid #ebebeb"}}>{props.title}</h1>

          <div className="col-sm-12 text-center mt-0 mb-1">
          <p>{props.subtitle}</p>
          </div>
          
          <div className="form-group tw-w-full tw-mt-8">
            <label>{{
              'en': <>Your name: <b>{myname}</b></>,
              'ru': <>Как к Вам обращаться: {myname}</>,
              'de': <>Name: {myname}</>
            }[props.lang]}</label>
            <Input type="text" className="form-control" placeholder={{
              'en': "please enter your name",
              'ru': 'Ваше имя, фамилия',
              'de': 'Nachname Vorname'
            }[props.lang]} onChange={(e)=>{setMyName(e.target.value);}} />
          </div>
          <div className="form-group tw-w-full">
            <label>{{
              'en': <>Your phone number: <b>{myphone}</b></>,
              'ru': <>Как с Вами созвониться: <b>{myphone}</b></>,
              'de': <>Telefonnummer: <b>{myphone}</b></>
            }[props.lang]}</label>
            <Input type="tel" className="form-control" placeholder={{
              'en': "e.g. +41 (21) --- -- --",
              'ru': 'Ваш номер телефона',
              'de': 'zum Beispiel +41 (21) --- -- --'
            }[props.lang]} onChange={(e)=>{setMyPhone(e.target.value);}}  />
          </div>
          <div className="form-group text-left tw-w-full">
            <label>{{
              'en': <>Your e-mail address: <b>{email}</b></>,
              'ru': <>Адрес электронной почты: <b>{email}</b></>,
              'de': <>E-Mail: <b>{email}</b></>
            }[props.lang]}</label>
            <Input type="email" className="form-control" placeholder={{
              'en': "---@---.---",
              'ru': "---@---.---",
              'de': "---@---.---"
            }[props.lang]} onChange={(e)=>{setEmail(e.target.value);}}   />
            <small id="emailHelp" className="form-text text-muted">{{
              'en': 'We\'ll never share your email with anyone else.',
              'ru': 'Мы никогда не поделимся Вашей электронной почтой.',
              'de': 'Wir werden Ihre E-Mail niemals an andere weitergeben.'
            }[props.lang]}</small>
          </div>
          <div className="form-check tw-w-full">
            <Input type="checkbox" className="form-check-input" onChange={(e)=>{setDiscl(e.target.checked);}} />
            <label className="form-check-label tw-text-justify">{{
              'en': 'I agree that my details and data will be electronically collected and sent to us securely via this form.',
              'ru': 'Я согласен с тем, что мои данные в электронном виде отправятся в Blackfort Capital AG.',
              'de': 'Ich bin damit einverstanden, dass meine Angaben und Daten elektronisch erfasst und sicher über dieses Formular an uns gesendet werden.'
            }[props.lang]}<br /><b>{{
              'en': 'Note:',
              'ru': 'Обратите внимание:',
              'de': 'Anmerkung:'
            }[props.lang]}</b>{{
              'en': ' You can revoke your consent at any time for the future by sending an email to us.',
              'ru': ' По Вашему запросу мы удалим все Ваши персональные данные.',
              'de': ' Sie können Ihre Zustimmung jederzeit widerrufen und uns eine E-Mail schicken, um Ihre Informationen zu entfernen.'
            }[props.lang]}</label>
          </div>
          <button onClick={discl ? send_details : ()=>{}} type="button" className={discl ? "btn btn-warning font-weight-bold tw-mt-8" : "btn btn-warning font-weight-bold tw-mt-8 disabled"} style={{color:'black'}}>{{
              'en': 'Click here and we will contact you',
              'ru': 'Нажмите и мы с Вами свяжемся',
              'de': 'Klicken Sie hier und wir werden Sie kontaktieren'
            }[props.lang]}</button>
          <PointS text={thank} mtop="4" />
          </div>
          
        </Modal>
      </>
    );
  };

export default Contacts