import {PointS, Quote} from '../Misc';
import deflag from "../../assets/images/de.png";
import ruflag from "../../assets/images/ru.png";
import enflag from "../../assets/images/en.png";
import { Helmet } from 'react-helmet';
import {useState} from 'react';
import {Modal, Input} from 'reactstrap';

function Acceptance(props) {

    return (
      <>
      <Modal
          isOpen="1"
          size="lg"
          contentClassName="tw-rounded-lg"
          key="acceptance"
          id="acceptance">
      <div className="tw-text-sm lg:tw-text-base tw-ease-in tw-flex tw-flex-col tw-items-center tw-p-4 lg:tw-p-8">
        <Helmet>
          <title>{props.ui.titles[props.ui.state.lang]}</title>
        </Helmet>
        <div className="tw-flex tw-flex-row tw-justify-center md:tw-justify-end tw-w-full tw-h-5">
          <img src={deflag} className={props.ui.state.fading_lang == 'de' ? "tw-inline tw-mr-1 tw-w-8" : "tw-transition-opacity tw-duration-200 tw-ease-in tw-inline tw-mr-1 tw-opacity-20 tw-cursor-pointer tw-w-7"} onClick={()=>{props.ui.switch_lang('de')}} />
          <img src={enflag} className={props.ui.state.fading_lang == 'en' ? "tw-inline tw-mr-1 tw-w-8" : "tw-transition-opacity tw-duration-200 tw-ease-in tw-inline tw-mr-1 tw-opacity-20 tw-cursor-pointer tw-w-7"} onClick={()=>{props.ui.switch_lang('en')}} />
          <img src={ruflag} className={props.ui.state.fading_lang == 'ru' ? "tw-inline tw-mr-1 tw-w-8" : "tw-transition-opacity tw-duration-200 tw-ease-in tw-inline tw-mr-1 tw-opacity-20 tw-cursor-pointer tw-w-7"} onClick={()=>{props.ui.switch_lang('ru')}} />
        </div>
        <div className={props.ui.state.fadeout ? "tw-transition-opacity tw-duration-500 tw-ease-in tw-w-full tw-opacity-50" : "tw-w-full"} >
          <h1 className="tw-text-2xl lg:tw-text-4xl tw-font-bold tw-text-center tw-my-8" style={{
            borderBottom: "1px solid #ebebeb"}}>{{
              'en': 'Blackfort Capital Disclaimer',
              'ru': 'Условия доступа к информации',
              'de': 'Blackfort Capital AG Haftungsausschluss'
            }[props.lang]}</h1>

          <div className="modal-body">
            <Quote text={{
              'en':<>In order to view the information relating to the <b>Blackfort Daily Quant Accelerator I</b>, you must accept the terms and conditions set out below, and our separate Terms of use. By clicking <span className="tw-py-0 btn btn-warning font-weight-bold tw-rounded tw-px-2" onClick={()=>{props.ui.setState({'accepted':1})}}>I accept</span> below and accessing the information and material available on the Website, you accept these Terms.</>,
              'ru':<>Чтобы получить информацию о нашем инвестиционном продукте <b>Ежедневный доходный ускоритель Блекфорт I</b>, просим Вас принять условия перечисленные ниже. При нажатии кнопки <span className="tw-py-0 btn btn-warning font-weight-bold tw-rounded tw-px-2" onClick={()=>{props.ui.setState({'accepted':1})}}>Я принимаю</span> Вы соглашаетесь со всеми условиями доступа к информации об инвестиционном продукте на нашем сайте.</>,
              'de':<>Um die Informationen bezüglich des <b>Blackfort Daily Quant Accelerator I</b> einsehen zu können, müssen Sie die unten aufgeführten Bedingungen sowie unsere separaten Nutzungsbedingungen akzeptieren. Indem Sie unten auf <span className="tw-py-0 btn btn-warning font-weight-bold tw-rounded tw-px-2" onClick={()=>{props.ui.setState({'accepted':1})}}>Ich akzeptiere</span> klicken und auf die auf der Website verfügbaren Informationen und Materialien zugreifen, akzeptieren Sie diese Bedingungen.</>}[props.lang]} mtop="0" />

            <Quote text={{
              'en':<>The website and the information contained herein is not intended to be a source of investment advice or credit analysis with respect to the material and/or presented. The material presented to you in this website is not and should not be considered as an offer, invitation or recommendation to sell or a solicitation of an offer to buy any of the securities in any jurisdiction. The information provided does not take into account your particular investment objectives, financial situation or needs. Before acting on any information you should consider the appropriateness of the information having regard to these matters.</>,
              'ru':<>Этот сайт и вся информация на нем не являются индивидуальной инвестиционной рекомендацией, советом, оценкой кредитоспособности и/или инвестиционной надежности финансовых инструментов. Этот сайт не является нашим инвестиционным предложением; рекомендацией совершать сделки с финансовыми инструментами; материалом рекламного характера; или материалом, преследующим цель распространения финансовых продуктов и/или услуг в какой-либо юрисдикции. Предоставленная информация на сайте никак не учитывает Ваших инвестиционных целей и/или Вашей финансовой ситуации. Перед тем, как принимать какое-либо решение на основе данных материалов, Вам необходимо должным образом оценить степень соответствия предложенных стратегий и/или финансовых продуктов.</>,
              'de':<>Die Website und die darin enthaltenen Informationen sind nicht als Quelle für Anlageberatung oder Kreditanalyse in Bezug auf das Material und/oder die dargestellten Informationen gedacht. Das Ihnen auf dieser Website präsentierte Material ist nicht und sollte nicht als Angebot, Einladung oder Empfehlung zum Verkauf oder als Aufforderung zur Abgabe eines Angebots zum Kauf von Wertpapieren in irgendeiner Rechtsordnung betrachtet werden. Die bereitgestellten Informationen berücksichtigen nicht Ihre besonderen Anlageziele, Ihre finanzielle Situation oder Ihre Bedürfnisse. Bevor Sie aufgrund von Informationen handeln, sollten Sie die Angemessenheit der Informationen im Hinblick auf diese Angelegenheiten prüfen.</>}[props.lang]} mtop="0" ptop="4" />            

            <p className="tw-pl-4 tw-py-8 tw-font-bold"> {{
              'en': 'I confirm that:',
              'ru': 'Я подтверждаю, что',
              'de': 'Ich bestätige folgendes:'
              }[props.lang]}</p>
            <PointS text={{
              'en':"I have read and accept the Terms and Conditions of using this website, detailed above;",
              'ru':"Я ознакомлен(а) со всеми условиями выше, а также с условиями, изложенными в документе \"Условиях предоставления информации о наших услугах\";",
              'de':"Ich habe die oben aufgeführten Bedingungen für die Nutzung dieser Website gelesen und akzeptiere sie;"}[props.lang]} mtop="0" />
            <PointS text={{
              'en':"I am a sophisticated investor;",
              'ru':"Я являюсь опытным инвестором;",
              'de':"Ich bin ein qualifizierter Investor;"}[props.lang]} />
            <PointS text={{
              'en':"I will not make an investment in an investment Product, or advise another to do so, if the promotion of the investment Product to the type of investor I represent is prohibited;",
              'ru':"Я не буду принимать инвестиционные решения, советы, и/или рекомендации, если их распространение не разрешено в моей юрисдикции для таких инвесторов, как я;",
              'de':"Ich werde nicht in ein Anlageprodukt investieren oder einem anderen raten, dies zu tun, wenn die Werbung für das Anlageprodukt bei dem von mir vertretenen Anlegertyp verboten ist;"}[props.lang]} />
            <PointS text={{
              'en':"I recognise that the information provided on this website is for information purposes only and is not a solicitation of an investment;",
              'ru':"Я осознаю, что информация, представленная на этом сайте во всех его разделах, предназначена только для ознакомления с финансовым продуктом и не является советом, рекомендацией, или рекламой в целях распространения финансовых инструментов и/или услуг;",
              'de':"Ich erkenne an, dass die auf dieser Website bereitgestellten Informationen nur zu Informationszwecken dienen und keine Werbung für eine Investition darstellen;"}[props.lang]} />
            <PointS text={{
              'en':"I will study the prospectus, KIID or Offering Memorandum of an Investment Product, including the risk factors before making an investment.",
              'ru':"Я внимательно ознакомлюсь с инвестиционным меморандумом, документом KIID или инвестиционным проспектом каждого интересующего меня финансового продукта. Я изучу инвестиционные риски и риск-факторы перед тем, как буду принимать какое-либо инвестиционное решение или совершать сделку с каким-либо финансовым продуктом.",
              'de':"Ich werde den Prospekt, KIID oder das Offering Memorandum eines Anlageprodukts einschließlich der Risikofaktoren studieren, bevor ich eine Investition tätige."}[props.lang]} />

          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-warning font-weight-bold" onClick={()=>{props.ui.setState({'accepted':1})}}>{{
              'en': 'I accept',
              'ru': 'Я принимаю',
              'de': 'Ich akzeptiere'
              }[props.lang]}</button>
            <a href="https://www.blackfort.ch/" className="tw-text-yellow-500 hover:tw-text-yellow-500">{{
              'en': 'or Leave webpage',
              'ru': 'Покинуть эту страницу',
              'de': 'oder Verlassen der Webseite'
              }[props.lang]}</a>
          </div>
        </div>
      </div>
      </Modal>
      </>
    )
}

export default Acceptance