import React from 'react';
import { ReactComponent as SpSVGInside } from './sp500.svg';
import * as d3 from "d3";

class SpCompany extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      'active': 0
    }
  }

  enter_active = () => {
    this.setState({'active': 1})
  }

  leave_active = () => {
    this.setState({'active': 0})
  }  

  render () {
    return (
      <>
        <path
          onMouseEnter={this.enter_active}
          onMouseLeave={this.leave_active}
          d={this.props.shape} style={{
          fill: this.props.color,
          fillOpacity: this.state.active ? 0.5 : 1,
          fillRule: "nonzero",
          stroke: "none"
        }} />
        <path
          onMouseEnter={this.enter_active}
          onMouseLeave={this.leave_active}
          d={this.state.active ? "m 401.68001,"+String(42.59+17.28*parseInt(this.props.i))+" h 19.86 v 15.86 h -19.86 z" : 
          "m 403.68001,"+String(42.59+17.28*parseInt(this.props.i))+" h 15.86 v 15.86 h -15.86 z"
        }
          style={{
            fill: this.props.color,
            fillOpacity: this.state.active ? 0.5 : 1,
            fillRule: "nonzero",
            stroke: "none"
          }} />
        <text
          onMouseEnter={this.enter_active}
          onMouseLeave={this.leave_active}
          transform="scale(1,-1)"
          style={{
            fontSize: "0.8em",
            fontFamily: "Raleway",
            fill: "#000000",
            fillOpacity: this.state.active ? 0.5 : 1,
            fillRule: "nonzero",
            stroke: "none"
          }}
          x="427"
          y={String(-(46+17.28*parseInt(this.props.i)))}>{this.props.name}</text>
      </>
    )
  }
}

class SpSVG extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      'data': [
        {'color': '#ebebeb', 'name': {'en':'480+ other U.S. stocks','ru':'480+ других акций США','de':'480+ andere U.S.-Aktien'}},
        {'color': '#ebe7e0', 'name': {'en':'PayPal','ru':'Пэйпал','de':'PayPal'}},
        {'color': '#ece3d5', 'name': {'en':'Adobe','ru':'Адобе','de':'Adobe'}},
        {'color': '#ece0ca', 'name': {'en':'Netflix','ru':'Нетфликс','de':'Netflix'}},
        {'color': '#eddcbf', 'name': {'en':'Walt Disney','ru':'Волт Дисней','de':'Walt Disney'}},
        {'color': '#eed9b4', 'name': {'en':'Verizon Communications','ru':'Верайзон','de':'Verizon Communications'}},
        {'color': '#eed5a9', 'name': {'en':'Home Depot','ru':'Хоум Дипо','de':'Home Depot'}},
        {'color': '#efd29f', 'name': {'en':'Mastercard','ru':'Мастеркард','de':'Mastercard'}},
        {'color': '#f0ce94', 'name': {'en':'United Health','ru':'Группа United Health','de':'United Health'}},
        {'color': '#f0cb89', 'name': {'en':'NVIDIA','ru':'Корпорация NVIDIA','de':'NVIDIA'}},
        {'color': '#f1c77e', 'name': {'en':'JPMorgan Chase','ru':'Банк JPMorgan Chase','de':'JPMorgan Chase'}},
        {'color': '#f1c473', 'name': {'en':'Procter & Gamble','ru':'Проктер & Гэмбл','de':'Procter & Gamble'}},
        {'color': '#f2c068', 'name': {'en':'Visa','ru':'Виза','de':'Visa'}},
        {'color': '#f3bd5e', 'name': {'en':'Johnson & Johnson','ru':'Джонсон и Джонсон','de':'Johnson & Johnson'}},
        {'color': '#f3b953', 'name': {'en':'Berkshire Hathaway','ru':'Холдинг Berkshire Hathaway','de':'Berkshire Hathaway'}},
        {'color': '#f4b648', 'name': {'en':'Facebook','ru':'Фейсбук','de':'Facebook'}},
        {'color': '#f5b23d', 'name': {'en':'Google','ru':'Гугл','de':'Google'}},
        {'color': '#f5af32', 'name': {'en':'Amazon','ru':'Амазон','de':'Amazon'}},
        {'color': '#f6ab27', 'name': {'en':'Microsoft','ru':'Майкрософт','de':'Microsoft'}},
        {'color': '#f7a81d', 'name': {'en':'Apple','ru':'Эппл','de':'Apple'}},
      ],
      'curves': {
        0: "m 219.64,222.58 -2.34,-2.38 -2.35,-2.38 -2.35,-2.38 -2.34,-2.38 -2.35,-2.38 -2.34,-2.38 -2.35,-2.39 -2.35,-2.38 -2.34,-2.38 -2.35,-2.38 -2.34,-2.38 -2.35,-2.38 -2.35,-2.38 -2.34,-2.38 -2.35,-2.38 -2.34,-2.39 -2.35,-2.38 -2.35,-2.38 -2.34,-2.38 -2.35,-2.38 -2.34,-2.38 -2.35,-2.38 -2.35,-2.38 -2.34,-2.38 -2.35,-2.39 -2.34,-2.38 -2.35,-2.38 -2.35,-2.38 -2.34,-2.38 2.39,-2.28 2.47,-2.19 2.54,-2.11 2.61,-2.03 2.68,-1.93 2.75,-1.84 2.8,-1.74 2.87,-1.65 2.91,-1.55 2.97,-1.45 3.02,-1.35 3.06,-1.24 3.1,-1.14 3.14,-1.03 3.17,-0.93 3.2,-0.81 3.23,-0.71 3.25,-0.6 3.27,-0.48 3.28,-0.38 3.29,-0.26 3.31,-0.15 3.3,-0.03 3.3,0.07 3.3,0.19 3.29,0.3 3.28,0.41 3.26,0.53 3.24,0.63 3.22,0.75 3.19,0.85 3.16,0.96 3.13,1.07 3.09,1.18 3.04,1.28 3,1.38 2.95,1.49 2.9,1.58 2.85,1.68 2.78,1.78 2.72,1.88 2.66,1.96 2.59,2.05 2.52,2.14 2.44,2.23 2.36,2.3 2.29,2.39 2.2,2.46 2.12,2.54 2.03,2.6 1.94,2.68 1.85,2.74 1.75,2.8 1.66,2.86 1.56,2.91 1.45,2.96 1.36,3.02 1.25,3.05 1.15,3.1 1.04,3.14 0.94,3.17 0.82,3.19 0.72,3.23 0.61,3.25 0.49,3.26 0.38,3.29 0.28,3.29 0.15,3.3 0.05,3.3 -0.07,3.3 -0.17,3.3 -0.29,3.29 -0.41,3.28 -0.51,3.27 -0.63,3.24 -0.73,3.22 -0.85,3.2 -0.95,3.16 -1.06,3.13 -1.17,3.09 -1.27,3.05 -1.37,3 -1.48,2.96 -1.58,2.9 -1.67,2.85 -1.77,2.79 -1.86,2.73 -1.96,2.66 -2.04,2.6 -2.14,2.52 -2.21,2.45 -2.3,2.37 -2.38,2.29 -2.46,2.21 -2.53,2.13 -2.6,2.04 -2.67,1.94 -2.73,1.86 -2.8,1.76 -2.85,1.67 -2.91,1.56 -2.96,1.47 -3.01,1.37 -3.05,1.26 -3.09,1.16 -3.14,1.05 -3.16,0.94 -3.2,0.84 -3.22,0.72 -3.25,0.62 -3.26,0.5 -3.28,0.4 -3.29,0.28 -3.3,0.17 -3.31,0.05 v -3.34 -3.34 -3.35 -3.34 -3.34 -3.34 -3.35 -3.34 -3.34 -3.35 -3.34 -3.34 -3.34 -3.35 -3.34 -3.34 -3.34 -3.35 -3.34 -3.34 -3.35 -3.34 -3.34 -3.34 -3.35 -3.34 -3.34 -3.34 z",
        1: "m 219.64,222.58 -2.46,-2.26 -2.46,-2.26 -2.46,-2.26 -2.46,-2.26 -2.47,-2.26 -2.46,-2.27 -2.46,-2.26 -2.46,-2.26 -2.46,-2.26 -2.46,-2.26 -2.47,-2.26 -2.46,-2.26 -2.46,-2.26 -2.46,-2.27 -2.46,-2.26 -2.47,-2.26 -2.46,-2.26 -2.46,-2.26 -2.46,-2.26 -2.46,-2.26 -2.46,-2.27 -2.47,-2.26 -2.46,-2.26 -2.46,-2.26 -2.46,-2.26 -2.46,-2.26 -2.46,-2.26 -2.47,-2.26 -2.46,-2.27 3.36,-3.47 2.34,2.38 2.35,2.38 2.35,2.38 2.34,2.38 2.35,2.39 2.34,2.38 2.35,2.38 2.35,2.38 2.34,2.38 2.35,2.38 2.34,2.38 2.35,2.38 2.35,2.38 2.34,2.39 2.35,2.38 2.34,2.38 2.35,2.38 2.35,2.38 2.34,2.38 2.35,2.38 2.34,2.38 2.35,2.38 2.35,2.39 2.34,2.38 2.35,2.38 2.34,2.38 2.35,2.38 2.35,2.38 z",
        2: "m 219.64,222.58 -2.57,-2.13 -2.57,-2.13 -2.58,-2.14 -2.57,-2.13 -2.57,-2.13 -2.58,-2.14 -2.57,-2.13 -2.57,-2.14 -2.58,-2.13 -2.57,-2.13 -2.57,-2.14 -2.58,-2.13 -2.57,-2.13 -2.57,-2.14 -2.58,-2.13 -2.57,-2.13 -2.57,-2.14 -2.58,-2.13 -2.57,-2.13 -2.57,-2.14 -2.58,-2.13 -2.57,-2.14 -2.57,-2.13 -2.58,-2.13 -2.57,-2.14 -2.57,-2.13 -2.58,-2.13 -2.57,-2.14 -2.57,-2.13 3.23,-3.71 2.46,2.27 2.47,2.26 2.46,2.26 2.46,2.26 2.46,2.26 2.46,2.26 2.46,2.26 2.47,2.26 2.46,2.27 2.46,2.26 2.46,2.26 2.46,2.26 2.46,2.26 2.47,2.26 2.46,2.26 2.46,2.27 2.46,2.26 2.46,2.26 2.47,2.26 2.46,2.26 2.46,2.26 2.46,2.26 2.46,2.26 2.46,2.27 2.47,2.26 2.46,2.26 2.46,2.26 2.46,2.26 z",
        3: "m 219.64,222.58 -2.68,-2 -2.67,-2 -2.68,-2 -2.68,-2 -2.68,-2 -2.68,-2 -2.68,-2 -2.68,-2 -2.67,-2 -2.68,-2 -2.68,-2 -2.68,-2 -2.68,-2 -2.68,-2 -2.67,-2 -2.68,-2 -2.68,-2 -2.68,-2 -2.68,-2 -2.68,-2 -2.68,-2 -2.67,-2 -2.68,-2 -2.68,-1.99 -2.68,-2 -2.68,-2 -2.68,-2 -2.67,-2 -2.68,-2 3.05,-3.88 2.57,2.13 2.57,2.14 2.58,2.13 2.57,2.13 2.57,2.14 2.58,2.13 2.57,2.13 2.57,2.14 2.58,2.13 2.57,2.14 2.57,2.13 2.58,2.13 2.57,2.14 2.57,2.13 2.58,2.13 2.57,2.14 2.57,2.13 2.58,2.13 2.57,2.14 2.57,2.13 2.58,2.13 2.57,2.14 2.57,2.13 2.58,2.14 2.57,2.13 2.57,2.13 2.58,2.14 2.57,2.13 z",
        4: "m 219.64,222.58 -2.78,-1.85 -2.79,-1.84 -2.78,-1.85 -2.79,-1.85 -2.78,-1.85 -2.79,-1.85 -2.78,-1.85 -2.79,-1.85 -2.78,-1.85 -2.79,-1.84 -2.78,-1.85 -2.79,-1.85 -2.78,-1.85 -2.79,-1.85 -2.78,-1.85 -2.79,-1.85 -2.78,-1.85 -2.79,-1.84 -2.78,-1.85 -2.79,-1.85 -2.78,-1.85 -2.79,-1.85 -2.78,-1.85 -2.79,-1.85 -2.78,-1.85 -2.79,-1.84 -2.78,-1.85 -2.79,-1.85 -2.78,-1.85 3.09,-4.38 2.68,2 2.67,2 2.68,2 2.68,2 2.68,2 2.68,1.99 2.68,2 2.67,2 2.68,2 2.68,2 2.68,2 2.68,2 2.68,2 2.68,2 2.67,2 2.68,2 2.68,2 2.68,2 2.68,2 2.68,2 2.67,2 2.68,2 2.68,2 2.68,2 2.68,2 2.68,2 2.68,2 2.67,2 z",
        5: "m 219.64,222.58 -2.88,-1.69 -2.89,-1.68 -2.88,-1.69 -2.89,-1.69 -2.88,-1.69 -2.89,-1.69 -2.88,-1.68 -2.89,-1.69 -2.88,-1.69 -2.89,-1.69 -2.88,-1.69 -2.89,-1.69 -2.88,-1.68 -2.89,-1.69 -2.88,-1.69 -2.89,-1.69 -2.88,-1.69 -2.89,-1.68 -2.88,-1.69 -2.89,-1.69 -2.88,-1.69 -2.89,-1.69 -2.88,-1.69 -2.89,-1.68 -2.89,-1.69 -2.88,-1.69 -2.89,-1.69 -2.88,-1.69 -2.89,-1.68 2.91,-4.66 2.78,1.85 2.79,1.85 2.78,1.85 2.79,1.84 2.78,1.85 2.79,1.85 2.78,1.85 2.79,1.85 2.78,1.85 2.79,1.85 2.78,1.85 2.79,1.84 2.78,1.85 2.79,1.85 2.78,1.85 2.79,1.85 2.78,1.85 2.79,1.85 2.78,1.85 2.79,1.84 2.78,1.85 2.79,1.85 2.78,1.85 2.79,1.85 2.78,1.85 2.79,1.85 2.78,1.85 2.79,1.84 z",
        6: "m 219.64,222.58 -2.99,-1.49 -2.99,-1.49 -2.99,-1.5 -2.99,-1.49 -2.99,-1.5 -2.99,-1.49 -2.99,-1.49 -2.99,-1.5 -2.99,-1.49 -2.99,-1.5 -2.99,-1.49 -2.99,-1.49 -2.99,-1.5 -2.99,-1.49 -2.99,-1.5 -2.99,-1.49 -2.99,-1.49 -2.99,-1.5 -2.99,-1.49 -2.99,-1.5 -2.99,-1.49 -2.99,-1.49 -2.99,-1.5 -2.99,-1.49 -3,-1.5 -2.99,-1.49 -2.99,-1.49 -2.99,-1.5 -2.99,-1.49 3.05,-5.63 2.89,1.68 2.88,1.69 2.89,1.69 2.88,1.69 2.89,1.69 2.89,1.68 2.88,1.69 2.89,1.69 2.88,1.69 2.89,1.69 2.88,1.69 2.89,1.68 2.88,1.69 2.89,1.69 2.88,1.69 2.89,1.69 2.88,1.68 2.89,1.69 2.88,1.69 2.89,1.69 2.88,1.69 2.89,1.69 2.88,1.68 2.89,1.69 2.88,1.69 2.89,1.69 2.88,1.69 2.89,1.68 z",
        7: "m 219.64,222.58 -3.08,-1.29 -3.08,-1.29 -3.09,-1.29 -3.08,-1.3 -3.08,-1.29 -3.08,-1.29 -3.09,-1.29 -3.08,-1.29 -3.08,-1.3 -3.09,-1.29 -3.08,-1.29 -3.08,-1.29 -3.08,-1.3 -3.09,-1.29 -3.08,-1.29 -3.08,-1.29 -3.09,-1.29 -3.08,-1.3 -3.08,-1.29 -3.09,-1.29 -3.08,-1.29 -3.08,-1.29 -3.08,-1.3 -3.09,-1.29 -3.08,-1.29 -3.08,-1.29 -3.09,-1.3 -3.08,-1.29 -3.08,-1.29 2.68,-5.85 2.99,1.49 2.99,1.5 2.99,1.49 2.99,1.49 3,1.5 2.99,1.49 2.99,1.5 2.99,1.49 2.99,1.49 2.99,1.5 2.99,1.49 2.99,1.5 2.99,1.49 2.99,1.49 2.99,1.5 2.99,1.49 2.99,1.5 2.99,1.49 2.99,1.49 2.99,1.5 2.99,1.49 2.99,1.5 2.99,1.49 2.99,1.49 2.99,1.5 2.99,1.49 2.99,1.5 2.99,1.49 z",
        8: "m 219.64,222.58 -3.16,-1.08 -3.16,-1.09 -3.16,-1.08 -3.17,-1.08 -3.16,-1.09 -3.16,-1.08 -3.16,-1.09 -3.16,-1.08 -3.16,-1.09 -3.17,-1.08 -3.16,-1.09 -3.16,-1.08 -3.16,-1.08 -3.16,-1.09 -3.17,-1.08 -3.16,-1.09 -3.16,-1.08 -3.16,-1.09 -3.16,-1.08 -3.17,-1.09 -3.16,-1.08 -3.16,-1.08 -3.16,-1.09 -3.16,-1.08 -3.17,-1.09 -3.16,-1.08 -3.16,-1.09 -3.16,-1.08 -3.16,-1.09 2.29,-6.02 3.08,1.29 3.08,1.29 3.09,1.3 3.08,1.29 3.08,1.29 3.09,1.29 3.08,1.3 3.08,1.29 3.08,1.29 3.09,1.29 3.08,1.29 3.08,1.3 3.09,1.29 3.08,1.29 3.08,1.29 3.09,1.29 3.08,1.3 3.08,1.29 3.08,1.29 3.09,1.29 3.08,1.3 3.08,1.29 3.09,1.29 3.08,1.29 3.08,1.29 3.08,1.3 3.09,1.29 3.08,1.29 z",
        9: "m 219.64,222.58 -3.22,-0.87 -3.23,-0.87 -3.23,-0.87 -3.23,-0.87 -3.22,-0.87 -3.23,-0.87 -3.23,-0.87 -3.23,-0.87 -3.22,-0.87 -3.23,-0.87 -3.23,-0.87 -3.23,-0.87 -3.22,-0.88 -3.23,-0.87 -3.23,-0.87 -3.23,-0.87 -3.22,-0.87 -3.23,-0.87 -3.23,-0.87 -3.22,-0.87 -3.23,-0.87 -3.23,-0.87 -3.23,-0.87 -3.22,-0.87 -3.23,-0.87 -3.23,-0.87 -3.23,-0.87 -3.22,-0.88 -3.23,-0.87 1.9,-6.2 3.16,1.09 3.16,1.08 3.16,1.09 3.16,1.08 3.17,1.09 3.16,1.08 3.16,1.09 3.16,1.08 3.16,1.08 3.17,1.09 3.16,1.08 3.16,1.09 3.16,1.08 3.16,1.09 3.17,1.08 3.16,1.09 3.16,1.08 3.16,1.08 3.16,1.09 3.17,1.08 3.16,1.09 3.16,1.08 3.16,1.09 3.16,1.08 3.16,1.09 3.17,1.08 3.16,1.08 3.16,1.09 z",
        10: "m 219.64,222.58 -3.28,-0.64 -3.28,-0.65 -3.28,-0.64 -3.28,-0.64 -3.28,-0.65 -3.28,-0.64 -3.28,-0.65 -3.28,-0.64 -3.28,-0.65 -3.28,-0.64 -3.28,-0.65 -3.28,-0.64 -3.28,-0.65 -3.28,-0.64 -3.28,-0.64 -3.28,-0.65 -3.28,-0.64 -3.28,-0.65 -3.28,-0.64 -3.28,-0.65 -3.28,-0.64 -3.28,-0.65 -3.28,-0.64 -3.28,-0.65 -3.28,-0.64 -3.28,-0.64 -3.28,-0.65 -3.28,-0.64 -3.28,-0.65 0.71,-3.29 0.82,-3.27 3.23,0.87 3.22,0.88 3.23,0.87 3.23,0.87 3.23,0.87 3.22,0.87 3.23,0.87 3.23,0.87 3.23,0.87 3.22,0.87 3.23,0.87 3.23,0.87 3.22,0.87 3.23,0.87 3.23,0.87 3.23,0.87 3.22,0.88 3.23,0.87 3.23,0.87 3.23,0.87 3.22,0.87 3.23,0.87 3.23,0.87 3.23,0.87 3.22,0.87 3.23,0.87 3.23,0.87 3.23,0.87 z",
        11: "m 219.64,222.58 -3.32,-0.39 -3.32,-0.39 -3.32,-0.39 -3.32,-0.39 -3.32,-0.39 -3.32,-0.39 -3.32,-0.39 -3.32,-0.4 -3.32,-0.39 -3.32,-0.39 -3.32,-0.39 -3.31,-0.39 -3.32,-0.39 -3.32,-0.39 -3.32,-0.39 -3.32,-0.39 -3.32,-0.39 -3.32,-0.4 -3.32,-0.39 -3.32,-0.39 -3.32,-0.39 -3.32,-0.39 -3.32,-0.39 -3.32,-0.39 -3.32,-0.39 -3.32,-0.39 -3.32,-0.39 -3.32,-0.4 -3.32,-0.39 0.51,-3.68 0.64,-3.67 3.28,0.65 3.28,0.64 3.28,0.65 3.28,0.64 3.28,0.64 3.28,0.65 3.28,0.64 3.28,0.65 3.28,0.64 3.28,0.65 3.28,0.64 3.28,0.65 3.28,0.64 3.28,0.65 3.28,0.64 3.28,0.64 3.28,0.65 3.28,0.64 3.28,0.65 3.28,0.64 3.28,0.65 3.28,0.64 3.28,0.65 3.28,0.64 3.28,0.65 3.28,0.64 3.28,0.64 3.28,0.65 z",
        12: "m 219.64,222.58 -3.34,-0.13 -3.34,-0.14 -3.34,-0.13 -3.34,-0.13 -3.34,-0.14 -3.34,-0.13 -3.34,-0.14 -3.34,-0.13 -3.34,-0.14 -3.34,-0.13 -3.34,-0.13 -3.34,-0.14 -3.34,-0.13 -3.34,-0.14 -3.34,-0.13 -3.34,-0.14 -3.34,-0.13 -3.34,-0.13 -3.34,-0.14 -3.34,-0.13 -3.34,-0.14 -3.34,-0.13 -3.34,-0.14 -3.34,-0.13 -3.34,-0.13 -3.34,-0.14 -3.34,-0.13 -3.34,-0.14 -3.34,-0.13 0.22,-3.73 0.37,-3.72 3.32,0.39 3.32,0.4 3.32,0.39 3.32,0.39 3.32,0.39 3.32,0.39 3.32,0.39 3.32,0.39 3.32,0.39 3.32,0.39 3.32,0.39 3.32,0.4 3.32,0.39 3.32,0.39 3.32,0.39 3.32,0.39 3.32,0.39 3.31,0.39 3.32,0.39 3.32,0.39 3.32,0.39 3.32,0.4 3.32,0.39 3.32,0.39 3.32,0.39 3.32,0.39 3.32,0.39 3.32,0.39 z",
        13: "m 219.64,222.58 -3.34,0.16 -3.34,0.17 -3.33,0.16 -3.34,0.16 -3.34,0.16 -3.34,0.16 -3.34,0.16 -3.34,0.17 -3.34,0.16 -3.34,0.16 -3.33,0.16 -3.34,0.16 -3.34,0.16 -3.34,0.16 -3.34,0.17 -3.34,0.16 -3.34,0.16 -3.34,0.16 -3.34,0.16 -3.33,0.16 -3.34,0.17 -3.34,0.16 -3.34,0.16 -3.34,0.16 -3.34,0.16 -3.34,0.16 -3.34,0.17 -3.33,0.16 -3.34,0.16 -0.12,-4.29 0.08,-4.29 3.34,0.13 3.34,0.14 3.34,0.13 3.34,0.14 3.34,0.13 3.34,0.13 3.34,0.14 3.34,0.13 3.34,0.14 3.34,0.13 3.34,0.14 3.34,0.13 3.34,0.13 3.34,0.14 3.34,0.13 3.34,0.14 3.34,0.13 3.34,0.14 3.34,0.13 3.34,0.13 3.34,0.14 3.34,0.13 3.34,0.14 3.34,0.13 3.34,0.14 3.34,0.13 3.34,0.13 3.34,0.14 z",
        14: "m 219.64,222.58 -3.31,0.49 -3.3,0.48 -3.31,0.48 -3.31,0.48 -3.31,0.49 -3.3,0.48 -3.31,0.48 -3.31,0.49 -3.31,0.48 -3.3,0.48 -3.31,0.49 -3.31,0.48 -3.31,0.48 -3.3,0.48 -3.31,0.49 -3.31,0.48 -3.31,0.48 -3.31,0.49 -3.3,0.48 -3.31,0.48 -3.31,0.49 -3.31,0.48 -3.3,0.48 -3.31,0.48 -3.31,0.49 -3.31,0.48 -3.3,0.48 -3.31,0.49 -3.31,0.48 -0.56,-4.65 -0.34,-4.67 3.34,-0.16 3.33,-0.16 3.34,-0.17 3.34,-0.16 3.34,-0.16 3.34,-0.16 3.34,-0.16 3.34,-0.16 3.34,-0.17 3.33,-0.16 3.34,-0.16 3.34,-0.16 3.34,-0.16 3.34,-0.16 3.34,-0.17 3.34,-0.16 3.34,-0.16 3.34,-0.16 3.33,-0.16 3.34,-0.16 3.34,-0.16 3.34,-0.17 3.34,-0.16 3.34,-0.16 3.34,-0.16 3.34,-0.16 3.33,-0.16 3.34,-0.17 z",
        15: "m 219.64,222.58 -3.2,0.97 -3.2,0.97 -3.2,0.97 -3.2,0.96 -3.2,0.97 -3.2,0.97 -3.19,0.97 -3.2,0.96 -3.2,0.97 -3.2,0.97 -3.2,0.97 -3.2,0.96 -3.2,0.97 -3.2,0.97 -3.2,0.97 -3.2,0.96 -3.2,0.97 -3.2,0.97 -3.2,0.97 -3.2,0.96 -3.2,0.97 -3.2,0.97 -3.2,0.97 -3.2,0.97 -3.2,0.96 -3.2,0.97 -3.2,0.97 -3.2,0.97 -3.2,0.96 -0.98,-3.46 -0.84,-3.51 -0.72,-3.53 -0.59,-3.55 3.31,-0.48 3.31,-0.49 3.3,-0.48 3.31,-0.48 3.31,-0.49 3.31,-0.48 3.3,-0.48 3.31,-0.48 3.31,-0.49 3.31,-0.48 3.3,-0.48 3.31,-0.49 3.31,-0.48 3.31,-0.48 3.31,-0.49 3.3,-0.48 3.31,-0.48 3.31,-0.48 3.31,-0.49 3.3,-0.48 3.31,-0.48 3.31,-0.49 3.31,-0.48 3.3,-0.48 3.31,-0.49 3.31,-0.48 3.31,-0.48 3.3,-0.48 z",
        16: "m 219.64,222.58 -2.93,1.61 -2.93,1.6 -2.94,1.6 -2.93,1.61 -2.93,1.6 -2.94,1.6 -2.93,1.6 -2.93,1.61 -2.94,1.6 -2.93,1.6 -2.93,1.61 -2.94,1.6 -2.93,1.6 -2.93,1.61 -2.94,1.6 -2.93,1.6 -2.93,1.61 -2.94,1.6 -2.93,1.6 -2.93,1.61 -2.94,1.6 -2.93,1.6 -2.93,1.6 -2.94,1.61 -2.93,1.6 -2.93,1.6 -2.93,1.61 -2.94,1.6 -2.93,1.6 -1.55,-2.95 -1.45,-3.01 -1.34,-3.05 -1.24,-3.1 -1.13,-3.14 -1.02,-3.18 3.2,-0.96 3.2,-0.97 3.2,-0.97 3.2,-0.97 3.2,-0.96 3.2,-0.97 3.2,-0.97 3.2,-0.97 3.2,-0.97 3.2,-0.96 3.2,-0.97 3.2,-0.97 3.2,-0.97 3.2,-0.96 3.2,-0.97 3.2,-0.97 3.2,-0.97 3.2,-0.96 3.2,-0.97 3.2,-0.97 3.2,-0.97 3.2,-0.96 3.19,-0.97 3.2,-0.97 3.2,-0.97 3.2,-0.96 3.2,-0.97 3.2,-0.97 z",
        17: "m 219.64,222.58 -2.32,2.4 -2.33,2.4 -2.33,2.4 -2.32,2.4 -2.33,2.41 -2.33,2.4 -2.32,2.4 -2.33,2.4 -2.32,2.4 -2.33,2.4 -2.33,2.4 -2.32,2.4 -2.33,2.4 -2.33,2.4 -2.32,2.4 -2.33,2.4 -2.32,2.4 -2.33,2.4 -2.33,2.4 -2.32,2.4 -2.33,2.4 -2.33,2.4 -2.32,2.4 -2.33,2.4 -2.32,2.4 -2.33,2.41 -2.33,2.4 -2.32,2.4 -2.33,2.4 -2.57,-2.59 -2.47,-2.68 -2.37,-2.77 -2.26,-2.86 -2.16,-2.94 -2.04,-3.02 -1.92,-3.1 -1.81,-3.17 2.93,-1.6 2.94,-1.6 2.93,-1.61 2.93,-1.6 2.93,-1.6 2.94,-1.61 2.93,-1.6 2.93,-1.6 2.94,-1.6 2.93,-1.61 2.93,-1.6 2.94,-1.6 2.93,-1.61 2.93,-1.6 2.94,-1.6 2.93,-1.61 2.93,-1.6 2.94,-1.6 2.93,-1.61 2.93,-1.6 2.94,-1.6 2.93,-1.61 2.93,-1.6 2.94,-1.6 2.93,-1.6 2.93,-1.61 2.94,-1.6 2.93,-1.6 z",
        18: "m 219.64,222.58 -1.36,3.06 -1.36,3.05 -1.36,3.05 -1.36,3.06 -1.36,3.05 -1.36,3.05 -1.36,3.06 -1.36,3.05 -1.36,3.05 -1.36,3.06 -1.37,3.05 -1.36,3.05 -1.36,3.06 -1.36,3.05 -1.36,3.05 -1.36,3.05 -1.36,3.06 -1.36,3.05 -1.36,3.05 -1.36,3.06 -1.36,3.05 -1.36,3.05 -1.36,3.06 -1.36,3.05 -1.37,3.05 -1.36,3.06 -1.36,3.05 -1.36,3.05 -1.36,3.06 -3.08,-1.44 -3.02,-1.54 -2.97,-1.65 -2.91,-1.76 -2.85,-1.85 -2.78,-1.95 -2.71,-2.05 -2.64,-2.14 -2.56,-2.23 -2.48,-2.32 2.33,-2.4 2.32,-2.4 2.33,-2.4 2.33,-2.41 2.32,-2.4 2.33,-2.4 2.32,-2.4 2.33,-2.4 2.33,-2.4 2.32,-2.4 2.33,-2.4 2.33,-2.4 2.32,-2.4 2.33,-2.4 2.32,-2.4 2.33,-2.4 2.33,-2.4 2.32,-2.4 2.33,-2.4 2.33,-2.4 2.32,-2.4 2.33,-2.4 2.32,-2.4 2.33,-2.4 2.33,-2.41 2.32,-2.4 2.33,-2.4 2.33,-2.4 z",
        19: "m 219.64,222.58 v 3.35 3.34 3.34 3.34 3.35 3.34 3.34 3.34 3.35 3.34 3.34 3.35 3.34 3.34 3.34 3.35 3.34 3.34 3.34 3.35 3.34 3.34 3.35 3.34 3.34 3.34 3.35 3.34 3.34 l -3.38,-0.06 -3.39,-0.17 -3.37,-0.3 -3.36,-0.41 -3.35,-0.53 -3.32,-0.65 -3.3,-0.76 -3.27,-0.88 -3.24,-0.99 -3.2,-1.1 -3.16,-1.22 -3.12,-1.32 1.36,-3.06 1.36,-3.05 1.36,-3.05 1.36,-3.06 1.37,-3.05 1.36,-3.05 1.36,-3.06 1.36,-3.05 1.36,-3.05 1.36,-3.06 1.36,-3.05 1.36,-3.05 1.36,-3.06 1.36,-3.05 1.36,-3.05 1.36,-3.05 1.36,-3.06 1.36,-3.05 1.37,-3.05 1.36,-3.06 1.36,-3.05 1.36,-3.05 1.36,-3.06 1.36,-3.05 1.36,-3.05 1.36,-3.06 1.36,-3.05 1.36,-3.05 z"
      }
    }
  }

  componentDidMount() {
    //this.set_scripts();
  }

  set_scripts = () => {
    // Define the div for the tooltip
    d3.select("#sp_figure").selectAll("path")
    .on("mouseover",
        function (event, d) {
            const id = d3.select(this).attr('id').slice(-8);
            if (id.indexOf("stock") !== -1) {
                d3.select("#"+id).style("text-decoration", "underline");
                d3.select("#s"+id).style("opacity", 0.5);
                d3.select("#ss"+id).style("opacity", 0.5);
                };
            })
    .on("mouseout",
        function (event, d) {
            const id = d3.select(this).attr('id').slice(-8);
            if (id.indexOf("stock") !== -1) {
                d3.select("#"+id).style("text-decoration", "none");
                d3.select("#s"+id).style("opacity", 1);
                d3.select("#ss"+id).style("opacity", 1);	
                };
            });
  }

  render () {
    return (
      <div className="tw-flex tw-flex-col tw-items-center">
      <div className="tw-w-full md:tw-w-4/5">
      <div style={{
        position:"relative",
        paddingBottom:"70.83%",
        height:"0"}} className="tw-cursor-pointer tw-bg-white tw-rounded-2xl tw-w-full">
        <svg style={{
          position:"absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%"
        }}
          width="800"
          height="566.66669"
          viewBox="0 0 800 566.66669"><g
          transform="matrix(1.3333333,0,0,-1.3333333,0,566.66667)">
            <SpSVGInside />
            {this.state.data.map((x,i)=><SpCompany key={x.name['en']} name={x.name[this.props.lang]} shape={this.state.curves[i]} color={x.color} i={i} />)}
            <circle
              style={{
                fill: "#ffffff",
                fillCapacity: 1,
                fillRule: "evenodd",
                strokeWidth: 0.75
              }}
              id="path904"
              cx="219.64"
              cy="-222.58"
              r="53.080257"
              transform="scale(1,-1)" />
            <ellipse
              style={{
                fill: "#919191",
                fillCapacity: 1,
                fillRule: "evenodd",
                strokeWidth: 0.75
              }}
              cx="219.64"
              cy="-222.58"
              transform="scale(1,-1)"
              rx="34.549999"
              ry="34" />
            <text
              style={{
                fontSize: "2em",
                fontFamily: "Raleway",
                fill: "#000000",
                fillOpacity: 1,
                fillRule: "nonzero",
                stroke: "none",
                strokeWidth: 0.75,
                lineHeight: 1.25,
                fontWeight: "bold"
              }}
              x="207.64"
              y="-219.58"
              transform="scale(1,-1)">S&P 500</text>
            <text
            style={{
              fontSize: "2em",
              fontFamily: "Raleway",
              fill: "#f1c77e",
              fillOpacity: 1,
              fillRule: "nonzero",
              stroke: "none",
              strokeWidth: 0.75,
              lineHeight: 1.25,
              fontWeight: "bold"
            }}
            x="206.97832"
            y="-220.48439"
            transform="scale(1,-1)">S&P 500</text>
            <text
            style={{
              fontSize: "1.3em",
              fontFamily: "Raleway",
              fill: "#000000",
              fillOpacity: 1,
              fillRule: "nonzero",
              stroke: "none",
              strokeWidth: 0.75,
              lineHeight: 1.25,
              fontWeight: "normal"
            }}
            x={{
              'en': "55",
              'ru': "35",
              'de': "0",
            }[this.props.lang]}
            y="-367.66541"
            transform="scale(1,-1)">{{
              'en': 'Rule-based ',
              'de': 'Der regelbasierte ',
              'ru': 'Умный '
              }[this.props.lang]}<tspan
            style={{
              fontWeight: "bold"
            }}>{{
              'en': 'trading robot ',
              'de': 'Handelsalgorithmus',
              'ru': 'торговый робот '
              }[this.props.lang]}</tspan>{{
                'en': 'in',
                'de': '',
                'ru': 'в индексе'
                }[this.props.lang]}</text>
          </g>
        </svg>
      </div>
      </div>
      </div>
    );
  }

}

export default SpSVG;