import React from 'react';
import Hammer from "hammerjs";
import swipePNG from "../../assets/images/swipe.png";

class SwipeButton extends React.Component {
  constructor(props) {
    super(props);
    this.labels = {
      'en': ['Swipe right to proceed', 'Excellent! Please wait.'],
      'ru': ['Связаться с Blackfort Capital AG','Подождите, пожалуйста...'],
      'de': ['Klicken Sie hier',"Bitte warten Sie..."]
    }
    this.state = {
      'active': 0,
      'waiting': 0
    }
  }

  get_contacts = () => {
    console.log('Swiped!');
    //this.setState({'waiting': 1});
    this.props.onClick();
  }

  componentDidMount() {

    var cParent = this;

    function dragElement(event) {
      var elementToDrag = event.target;
      // deltaX tracks the distance dragged along the x-axis since the initial touch.
      //if (event.deltaX > 0) {
      elementToDrag.style.left = event.deltaX + 'px';
      //}
      //elementToDrag.style.transform = 'translateX(' + event.deltaX + 'px)';
    };

    function resetElement(event) {
        var elementToReset = event.target;
        elementToReset.style.left = 0;
        //elementToReset.style.transform = 'translateX(0 px)';
    };

    // Swipe options as recommended by:
    // https://github.com/EightMedia/hammer.js/wiki/Tips-&-Tricks#horizontal-swipe-and-drag
    var swipeOptions = { dragLockToAxis: true, dragBlockHorizontal: true, preventDefault: true };
    function initTouchListeners(touchableElement) {
        var touchControl = new Hammer(touchableElement, swipeOptions);
        touchControl.on("panright", dragElement)
                .on("panleft", dragElement)
                .on("panend", resetElement)
                .on("pancancel", resetElement)
                .on("swiperight", cParent.get_contacts);
    };

    var mbtn = document.getElementById("mybutton");
    initTouchListeners(mbtn);

  }

  render () {
    return (
      <>
      <div className="tw-flex tw-flex-col tw-items-center tw-w-full tw-mt-12 tw-mb-12">
      <div className="tw-w-40 lg:tw-w-1/3">
      <img id="mybutton" src={swipePNG} style={{
        position: "relative",
        width: "100%"
        }} />
      <br />
      <div id="mybutton2" className="btn btn-link tw-text-center tw-w-full" onClick={this.get_contacts} style={{color:"#ffa500"}}>{this.labels[this.props.lang][this.state.waiting]}</div>
      </div>
      </div>
      </>
    )
  }

}

export default SwipeButton

