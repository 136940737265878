import '../../index.css';

function Point(props) {
    return (
      <ul className="tw-mt-8" style={{paddingLeft: "10px", listStyleType: "none" }}>
        <li className="text-left bullet"><b>{props.text}</b></li>
      </ul>
    )
  }

function PointS(props) {
    return (
      <ul className={"tw-text-justify tw-mt-"+String(props.mtop || 8)} style={{paddingLeft: "10px", listStyleType: "none" }}>
        <li className="text-left bullet">{props.text}</li>
      </ul>
    )
  }
  
function Quote(props) {
    return (
      <blockquote className={"tw-text-justify tw-mt-"+String(props.mtop || 2)+" tw-pt-"+String(props.ptop || 0)}>
      <p>{props.text}</p>
      </blockquote>
    )
  }

export {Point, PointS, Quote};