import {Point, Quote} from '../Misc';

function Factsheet(props) {

    return (
        <>
        <Point text={{
          'en':"FACTSHEET - Blackfort Daily Quant Accelerator I",
          'ru':'Факты о Ежедневном доходном ускорителе Блекфорт I',
          'de':'FAKTENBLATT - Blackfort Daily Quant Accelerator I'}[props.lang]} />

        <Quote text={{
          'en':"Blackfort Daily Quant Accelerator I offers you a unique opportunity to invest your capital directly into our smart index-trading algorithm.",
          'ru':"Ежедневный доходный ускоритель Блекфорт I дает Вам возможность инвестировать в нашу стратегию алгоритмической роботизированной торговли индекса S&P 500.",
          'de':"Blackfort Daily Quant Accelerator I bietet Ihnen eine einzigartige Möglichkeit, Ihr Kapital direkt in unseren intelligenten Index-Handelsalgorithmus zu investieren."}[props.lang]} />

        <Quote text={{
          'en':"Our algorithm is rule-based, designed with the cutting-edge AI methods of modernized trading, and constitutes the most efficient equities investment available.",
          'ru':"Компания Blackfort Capital AG разработала этот алгоритм, используя последние достижения в области искусственного разума и инвестиций, на рынке акций.",
          'de':"Unser Algorithmus ist regelbasiert, wurde mit den neusten KI-Methoden des modernisierten Handels entwickelt und stellt eine sehr effiziente Aktienanlage dar."}[props.lang]} mtop="0" ptop="4" />

        {props.lang == 'en' ?
        <blockquote className="tw-pt-8">
        <table className="table table-striped table-hover table-sm w-100 tw-m-0">
        <tbody>
        <tr>
        <td className="text-right">Name</td>
        <td>Blackfort Daily Quant Accelerator I</td>
        </tr>
        <tr>
        <td className="text-right">Issuer</td>
        <td>Maps ETI AG</td>
        </tr>
        <tr>
        <td className="text-right">Underlying Issuer</td>
        <td>Pecunia SPC</td>
        </tr>
        <tr>
        <td className="text-right">ISIN</td>
        <td>CH0505798675</td>
        </tr>
        <tr>
        <td className="text-right">Valor</td>
        <td>50579867</td>
        </tr>
        <tr>
        <td className="text-right">Currency</td>
        <td>EUR</td>
        </tr>
        <tr>
        <td className="text-right">Underlying</td>
        <td>Algorithm investing into S&amp;P 500 via a rule-based trading</td>
        </tr>
        <tr>
        <td className="text-right">Issue Price</td>
        <td>100%</td>
        </tr>
        <tr>
        <td className="text-right">Placement Fee</td>
        <td>Non</td>
        </tr>
        <tr>
        <td className="text-right">Denomination</td>
        <td>Nominal EUR 1’000</td>
        </tr>
        <tr>
        <td className="text-right">Min. Investment Size</td>
        <td>EUR 1’000</td>
        </tr>
        <tr>
        <td className="text-right">Issue Size</td>
        <td>Up to EUR 10’000’000</td>
        </tr>
        <tr>
        <td className="text-right">Price Calculation</td>
        <td>Min. EUR 1’000</td>
        </tr>
        <tr>
        <td className="text-right">Secondary Market</td>
        <td>YES</td>
        </tr>
        <tr>
        <td className="text-right">Management Fee</td>
        <td>0.5% p.a.</td>
        </tr>
        <tr>
        <td className="text-right">Performance Fee</td>
        <td>30%, high watermark</td>
        </tr>
        <tr>
        <td className="text-right">Paying Agent</td>
        <td>ISP Securities AG</td>
        </tr>
        <tr>
        <td className="text-right">Custodian</td>
        <td>Admiral Markets Pty Ltd.</td>
        </tr>
        <tr>
        <td className="text-right">Administration</td>
        <td>iMaps Capital Markets SEZC</td>
        </tr>
        <tr>
        <td className="text-right">Stock Exchange</td>
        <td>Stuttgart Stock Exchange</td>
        </tr>
        </tbody>
        </table>
        </blockquote>
        : "" }

        {props.lang == 'ru' ?
        <blockquote className="tw-pt-8">
        <table className="table table-striped table-hover table-sm w-100 tw-m-0">
        <tbody>
        <tr>
        <td className="text-right">Название</td>
        <td>Ежедневный доходный ускоритель Блекфорт I</td>
        </tr>
        <tr>
        <td className="text-right">Эмитент</td>
        <td>Maps ETI AG</td>
        </tr>
        <tr>
        <td className="text-right">Эмитент актива</td>
        <td>Pecunia SPC</td>
        </tr>
        <tr>
        <td className="text-right">ISIN</td>
        <td>CH0505798675</td>
        </tr>
        <tr>
        <td className="text-right">Валор</td>
        <td>50579867</td>
        </tr>
        <tr>
        <td className="text-right">Валюта</td>
        <td>Евро</td>
        </tr>
        <tr>
        <td className="text-right">Актив</td>
        <td>Торговый алгоритм S&P 500, роботизированные директивные торги</td>
        </tr>
        <tr>
        <td className="text-right">Цена эмиссии</td>
        <td>100%</td>
        </tr>
        <tr>
        <td className="text-right">Тариф размещения</td>
        <td>Нет</td>
        </tr>
        <tr>
        <td className="text-right">Деноминация</td>
        <td>Номинал EUR 1’000</td>
        </tr>
        <tr>
        <td className="text-right">Мин. инвестиция</td>
        <td>EUR 1’000</td>
        </tr>
        <tr>
        <td className="text-right">Объем выпуска</td>
        <td>До EUR 10’000’000</td>
        </tr>
        <tr>
        <td className="text-right">Расчет цены от</td>
        <td>Мин. EUR 1’000</td>
        </tr>
        <tr>
        <td className="text-right">Вторичный рынок</td>
        <td>Есть</td>
        </tr>
        <tr>
        <td className="text-right">Тариф управления</td>
        <td>0.5% в год</td>
        </tr>
        <tr>
        <td className="text-right">Тариф прибыльности</td>
        <td>30%, high watermark</td>
        </tr>
        <tr>
        <td className="text-right">Агент</td>
        <td>ISP Securities AG</td>
        </tr>
        <tr>
        <td className="text-right">Попечитель</td>
        <td>Admiral Markets Pty Ltd.</td>
        </tr>
        <tr>
        <td className="text-right">Администрирование</td>
        <td>iMaps Capital Markets SEZC</td>
        </tr>
        <tr>
        <td className="text-right">Биржа</td>
        <td>Stuttgart Stock Exchange</td>
        </tr>
        </tbody>
        </table>
        </blockquote>
        : "" }

        {props.lang == 'de' ?
        <blockquote className="tw-pt-8">
        <table className="table table-striped table-hover table-sm w-100 tw-m-0">
        <tbody>
        <tr>
        <td className="text-right">Name</td>
        <td>Blackfort Daily Quant Accelerator I</td>
        </tr>
        <tr>
        <td className="text-right">Herausgeber</td>
        <td>Maps ETI AG</td>
        </tr>
        <tr>
        <td className="text-right">Underlying Herausgeber</td>
        <td>Pecunia SPC</td>
        </tr>
        <tr>
        <td className="text-right">ISIN</td>
        <td>CH0505798675</td>
        </tr>
        <tr>
        <td className="text-right">Valor</td>
        <td>50579867</td>
        </tr>
        <tr>
        <td className="text-right">Währung</td>
        <td>EUR</td>
        </tr>
        <tr>
        <td className="text-right">Underlying</td>
        <td>Algorithmus, der über einen regelbasierten Handel in den S&amp;P 500 investiert</td>
        </tr>
        <tr>
        <td className="text-right">Ausgabepreis</td>
        <td>100%</td>
        </tr>
        <tr>
        <td className="text-right">Vermittlungsgebühr</td>
        <td>Keine</td>
        </tr>
        <tr>
        <td className="text-right">Denomination</td>
        <td>Nominal EUR 1’000</td>
        </tr>
        <tr>
        <td className="text-right">Min. Investitionsvolumen</td>
        <td>EUR 1’000</td>
        </tr>
        <tr>
        <td className="text-right">Emissionsvolumen</td>
        <td>Bis zu EUR 10’000’000</td>
        </tr>
        <tr>
        <td className="text-right">Preisberechnung</td>
        <td>Min. EUR 1’000</td>
        </tr>
        <tr>
        <td className="text-right">Sekundärer Markt</td>
        <td>JA</td>
        </tr>
        <tr>
        <td className="text-right">Verwaltungsgebühr</td>
        <td>0.5% p.a.</td>
        </tr>
        <tr>
        <td className="text-right">Performancegebühr</td>
        <td>30%, High Watermark</td>
        </tr>
        <tr>
        <td className="text-right">Zahlstelle</td>
        <td>ISP Securities AG</td>
        </tr>
        <tr>
        <td className="text-right">Treuhänder</td>
        <td>Admiral Markets Pty Ltd.</td>
        </tr>
        <tr>
        <td className="text-right">Verwaltung</td>
        <td>iMaps Capital Markets SEZC</td>
        </tr>
        <tr>
        <td className="text-right">Wertpapierbörse</td>
        <td>Stuttgart Stock Exchange</td>
        </tr>
        </tbody>
        </table>
        </blockquote>
        : "" }

        <Quote text={{
          'en':"Core of the proprietary investment strategy are automated buy and sell orders in the highly liquid S&P 500 index. The trading algorithm is purely rule-based and not affected by any discretionary human behavior. It self-adapts to changing market environments. The goal is to generate smooth yields on a daily basis, which turn into consistent and attractive profits. The downside risk is limited to predefined levels.",
          'ru':"Основная идея нашей стратегии заключается в применении автоматизированных покупок и продаж очень ликвидного индекса на рынке акций S&P 500. Алгоритм полностью автоматический, не подвержен эмоциям и паникам на рынке, адаптируется к текущей ситуации на рынке. Цель нашего алгоритма обеспечить высокий уровень доходности каждый торговый день. Ваши риски строго ограничены и находятся под ежесекундным контролем.",
          'de':"Kern der proprietären Anlagestrategie sind automatisierte Kauf- und Verkaufsaufträge in den hochliquiden S&P 500 Index. Der Handelsalgorithmus ist rein regelbasiert und wird nicht durch menschliches, diskretionäres Verhalten beeinflusst. Der Algorithmus passt sich automatisch an ein sich veränderndes Marktumfeld an. Ziel ist es, auf täglicher Basis positive Renditen zu erwirtschaften. Das Verlustrisiko ist auf vordefinierte Niveaus begrenzt."}[props.lang]} mtop="0" ptop="4" />
        </>
    )
}

export default Factsheet;