import {Point, Quote} from '../Misc';
import SpSVG from '../SpSVG';
import PerfSVG from '../PerfSVG';

function Performance(props) {

    return (
        <>
        <Quote text={{
          'en':"The ETI Securities are backed by trading in highly liquid S&P 500 Index. The proprietary algorithm is purely rule-based and detached from any human decision-making. This is how a steady flow of regular profits is generated.",
          'ru':"Предлагаемые Вам инструменты основаны на алгоритмической торговле всемирно известного и самого ликвидного индекса S&P 500. Торговый алгоритм полностью роботизирован и не зависит от наших с Вами эмоций. Мы стремимся к ежедневному стабильному приросту Ваших вложений.",
          'de':"Die ETI-Wertpapiere basieren auf dem Handel mit dem hochliquiden S&P 500 Index. Ein proprietärer Algorithmus handelt rein regelbasiert und losgelöst von jeglichen menschlichen Emotionen. Ziel ist es, einen stetigen Fluss an regelmäßigen Gewinnen zu generieren."}
          [props.lang]} mtop="8" />
        
        <Point text={{
          'en':"The underlying:",
          'ru':"Об активе:",
          'de':"Das Underlying:"}[props.lang]} />
        <SpSVG lang={props.lang}/>

        <Point text={{
          'en':"Historical Performance:",
          'ru':"Отчет о прошлых результатах:",
          'de':"Historische Leistung:"}[props.lang]} />

        <Quote text={{
          'en':"Our trading strategy has proven itself historically:",
          'ru':"Наша торговая стратегия доказала свою эффективность на практике",
          'de':"Unsere Handelsstrategie hat sich historisch bewährt:"}[props.lang]} />

        <blockquote className="tw-pt-4">
        <table className="table table-striped table-hover table-sm w-100 tw-text-center tw-m-0">
            <thead>
            <tr>
            <th></th>
            <th>{{
              'en': "Overall performance % p.a.()*",
              'ru': "Годовая доходность %",
              'de': "Gesamtleistung % p.a.()*"
              }[props.lang]}</th>
            </tr>
            </thead>
            <tbody>
            <tr>
            <td>2021 <span className="tw-text-xs tw-text-gray-600">YTD</span></td>
            <td>26.0%</td>
            </tr>
            <tr>
            <td>2020</td>
            <td>27.6%</td>
            </tr>
            <tr>
            <td>2019</td>
            <td>46.0%</td>
            </tr>
            <tr>
            <td>2018</td>
            <td>41.1%</td>
            </tr>
            <tr>
            <td>2017</td>
            <td>45.2%</td>
            </tr>
            </tbody>
        </table>
        </blockquote>

        <Quote text={{
          'en':"Monthly trading results in 2021: up to +6.1% in one month!",
          'ru':"Ежемесячная доходность в 2021 году: до +6.1% в месяц!",
          'de':"Handelsergebnisse des Kalendermonats 2021: bis zu +6,1%"
          }[props.lang]} mtop="0" />

        <PerfSVG lang={props.lang} />

        <Quote text={{
          'en':"The proprietary rule-based trading algorithm enhanced the annual S&P500 Index return from 17.9% to 27.6%",
          'ru':"Наш алгоритм обеспечил годовую доходность 27.6% в 2020 году и тем самым превзошел годовую доходность 17.9% от пассивных инвестиций в индекс S&P 500.",
          'de':"Der proprietäre, regelbasierte Handelsalgorithmus verbesserte die jährliche Rendite des S&P 500 Index im Jahr 2020 von 17.9% auf 27.6%."}[props.lang]} mtop="0" />

        <Point text={{
          'en':"Disclosures:",
          'ru':"Юридические комментарии:",
          'de':"Offenlegungen:"}[props.lang]} />

        <Quote text={{
          'en':"The performance of the trading strategy presented here is historical, is based on the actual results for the years since 2017 onwards. The performance report can not in any way represent a guarantee or a promise of performance in the future.",
          'ru':"Данный отчет о результатах за прошлые годы нельзя интерпретировать как обещание или гарантию доходности в будущем. Упомянутые прогнозы основаны на прошлых финансовых результатах, в то время как будущая доходность подвержена существенной деловой, экономической и конкурентной неопределенности, не зависящей от Blackfort Capital AG.",
          'de':"Die Performance der hier vorgestellten Handelsstrategie basiert auf einem Backtesting sowie auf den tatsächlichen Ergebnissen für die Jahre seit 2017. Wir möchten daher ausdrücklich darauf hinweisen, dass historische Daten, Prognoserechnungen und andere Simulationen keine verlässlichen Indikatoren für die zukünftige Entwicklung sind."}[props.lang]} />

        <Quote text={{
          'en':"The ETI Securities are not principal protected and are a high-risk investment in the form of a debt instrument. The ETI Security holders are neither assured of repayment of the capital invested nor are they assured of payment of any return on such capital.",
          'ru':"Инвестиции в Ежедневный доходный ускоритель Блекфорт I являются высоко рисковыми и не застрахованы от возможных потерь капитала или падения доходности в будущем.",
          'de':"Die ETI-Wertpapiere sind nicht kapitalgeschützt und stellen eine riskante Investition in Form eines Schuldtitels dar. Den Inhabern von ETI-Wertpapieren ist weder die Rückzahlung des investierten Kapitals noch die Zahlung einer Rendite auf dieses Kapital zugesichert."}[props.lang]} mtop="0" ptop="4" />

        </>

    );
  }
  
export default Performance;