import React from 'react';
import ReactDOM from 'react-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import './tailwind.output.css';
import './index.css';
import './fonts.css';
import App from './App';
import {
  BrowserRouter as Router,
  useLocation
} from "react-router-dom";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function BlogPost() {
  let query = useQuery();
  return <App code={query.get("tick")} passw={query.get("tock")} lang={query.get("l")}/>
}

ReactDOM.render(
  <Router>
    <BlogPost />
  </Router>,
  document.getElementById('root')
);