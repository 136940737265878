import React, { useState, useEffect } from 'react';
import {PointS, Quote} from '../Misc';
import deflag from "../../assets/images/de.png";
import ruflag from "../../assets/images/ru.png";
import enflag from "../../assets/images/en.png";
import blackfort from "../../assets/images/logo.png";
import { Helmet } from 'react-helmet';
import {
  Carousel,
  Modal,
  CarouselItem,
  Dropdown, DropdownToggle, DropdownMenu, DropdownItem,
  Spinner
} from 'reactstrap';

function Questions(props) {

    const [qNum, setQNum] = useState(0);
    const [success, setSuccess] = useState(1);
    const [results, setResults] = useState({});
    const [qs, setQuestions] = useState({});

    useEffect(() => {
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
            "code": String(props.code),
            "passw": String(props.passw)
        })
      };
      fetch('https://es3r9cuvya9lsuwobsykuzhj.blackfort.io/api/get_q_langs', requestOptions)
      .then((response)=>response.json()).then((data)=>{
          setQuestions(data);
          if (data.meeting) {
            setSuccess(1);
            setQNum(5);
            return 1
          }
          if (!data.q1text) {
            setSuccess(1);
            setQNum(5);
            return 1;
          }
          setQNum(1);
          if (data.lang) {
            props.ui.switch_lang(data.lang);
          }
          if (data.results) {
            setResults(data.results);
          }
        })
    },[]);

    const sendResults = () => {
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
            "code": String(props.code),
            "results": JSON.stringify(results)
        })
      };
      fetch('https://es3r9cuvya9lsuwobsykuzhj.blackfort.io/api/get_q_langs', requestOptions)
      .then((response)=>response.json()).then((data)=>{
          if (!data.success) {
            setSuccess(0);
            setQNum(4);
            return 0;
          }
          setSuccess(1);
          setQNum(4);
        })
    }

    const updateq1Form = (answers, position, value, allflag, noneflag) => {
      if (position==allflag) {
        const updates = {};
        for (let i = 0; i < answers.length; i++) {
          updates[answers[i].name] = 1;
        }
        updates[noneflag] = 0;
        const newData = {...results, ...updates};
        setResults(newData);
        return 1;
      } else if (position==noneflag) {
        const updates = {};
        for (let i = 0; i < answers.length; i++) {
          updates[answers[i].name] = 0;
        }
        updates[noneflag] = 1;
        const newData = {...results, ...updates};
        setResults(newData);
        return 1;
      } else {
        const updates = {};
        updates[position] = value;
        updates[noneflag] = 0;
        updates[allflag] = 0;
        const newData = {...results, ...updates};
        setResults(newData);
        return 1;
      }
    }

    const updateq2Form = (answers, position, value) => {
      const updates = {};
      for (let i = 0; i < answers.length; i++) {
        updates[answers[i].name] = 0;
      }
      updates[position] = value;
      const newData = {...results, ...updates};
      setResults(newData);
      return 1;
    }

    return (
      <>
      <Modal
          isOpen="1"
          size="xl"
          contentClassName="tw-rounded-3xl"
          key="acceptance"
          id="acceptance">
      <div className="tw-text-sm lg:tw-text-base tw-ease-in tw-flex tw-flex-col tw-items-center tw-p-4 lg:tw-p-8">
        <Helmet>
          <title>{props.ui.titles[props.ui.state.lang]}</title>
        </Helmet>
        <div className="tw-flex tw-flex-row tw-justify-end tw-w-full tw-h-5">
        <Dropdown isOpen={props.ui.state.dropdownOpen} toggle={props.ui.toggle} className="tw-bg-gray-200 tw-rounded-2xl tw-shadow-inner tw-h-10 tw-flex tw-flex-row tw-justify-center tw-items-center">
          <DropdownToggle color="link" caret style={{color:"#f89521"}} className="tw-yellow-50 text-yellow-50">
            {props.ui.state.fading_lang == 'de' ? "Deutsch" : props.ui.state.fading_lang == 'en' ? "English" : "Русский"}
          </DropdownToggle>
          <DropdownMenu>
            {0 ? props.ui.state.fading_lang != 'de'? <DropdownItem onClick={()=>{props.ui.switch_lang('de')}}>zu Deutsch wechseln</DropdownItem> : null : null}
            {props.ui.state.fading_lang != 'en'? <DropdownItem onClick={()=>{props.ui.switch_lang('en')}}>English</DropdownItem> : null}
            {props.ui.state.fading_lang != 'ru'? <DropdownItem onClick={()=>{props.ui.switch_lang('ru')}}>Русский</DropdownItem> : null}
          </DropdownMenu>
        </Dropdown>
        </div>
        <div className={"tw-w-full tw-flex tw-flex-col tw-justify-center tw-items-center"} >

        <img src={blackfort} className="tw-inline tw-w-16" onClick={()=>{}} />

          { qNum == 0 ? <><div className="tw-w-full tw-flex tw-flex-row tw-justify-center pt-4"><Spinner style={{color:"#f89521"}} type="grow" /></div></> : qNum == 1 ? <>

            <h1 className="tw-text-2xl lg:tw-text-4xl tw-font-bold tw-text-center tw-my-8 tw-w-full" style={{
              borderBottom: "1px solid #ebebeb"}}>{qs.heading[props.lang]} <span className="tw-text-yellow-500">{qs.headingOrange[props.lang]}</span></h1>

            <div className="tw-bg-gray-200 tw-rounded-3xl px-2 tw-shadow-inner tw-w-full">
            <h2 className="tw-text-2xl lg:tw-text-2xl tw-text-center tw-mt-12 tw-mb-4 tw-text-gray-800">{qs.q1text[props.lang]}</h2>  

            <div className="tw-flex tw-flex-col md:tw-flex-row tw-pt-4 tw-flex-wrap tw-w-full tw-items-start tw-justify-start md:tw-justify-center">

              {qs.q1answers ? qs.q1answers.map((x,i)=><>

                <div className="tw-w-full md:tw-w-1/2 lg:tw-w-1/3 tw-p-2 tw-cursor-pointer" onClick={()=>{qs.q1type == "multiple" ? updateq1Form(qs.q1answers, x.name, results[x.name] ? 0 : 1, "q1ALL", "q1NONE") : updateq2Form(qs.q1answers, x.name, results[x.name] ? 0 : 1)}}>
                <div style={{borderTopWidth:4, borderBottomWidth:4, borderLeftWidth:4, borderRightWidth:4}} className={results[x.name] ? "tw-font-bold tw-w-full tw-rounded-2xl tw-flex tw-flex-row tw-justify-center tw-items-center tw-bg-green-200 tw-text-lg tw-border-green-500 tw-shadow-inner tw-px-2 tw-py-8 tw-text-center" : "tw-w-full tw-bg-white tw-rounded-2xl tw-flex tw-flex-row tw-justify-center tw-items-center tw-text-lg tw-shadow-lg tw-border-yellow-500 hover:tw-shadow-inner tw-px-2 tw-py-8 tw-text-center"}>
                {x.answer[props.lang]}
                </div>
                </div>
              
              </>) : null}

              <div className="tw-w-full tw-flex tw-flex-row tw-justify-between tw-px-3 tw-pb-5 tw-pt-8">
              {qNum > 1 ? <button style={{backgroundColor:"#ebebeb", borderRadius:"1rem"}} type="button" className="btn font-weight-bold tw-text-xl tw-py-3 tw-px-4 tw-shadow-lg hover:tw-shadow-inner" onClick={()=>{setQNum(0)}}>{qs.q1buttonback ? qs.q1buttonback[props.lang] : "Back"}</button> : <div></div> }
              {Object.keys(results).length ? <button style={{backgroundColor:"#f89521", borderRadius:"1rem"}} type="button" className="btn font-weight-bold tw-text-xl tw-py-3 tw-px-4 tw-shadow-lg hover:tw-shadow-inner" onClick={()=>{(results['q1NONE']) ? sendResults() : setQNum(2)}}>{qs.q1button[props.lang]}</button> : <div></div> }
              </div>

            </div>
          </div>

          </> : 

          qNum == 2 ? <>

            <h1 className="tw-text-2xl lg:tw-text-4xl tw-font-bold tw-text-center tw-my-8 tw-w-full" style={{
              borderBottom: "1px solid #ebebeb"}}>{qs.heading[props.lang]} <span className="tw-text-yellow-500">{qs.headingOrange[props.lang]}</span></h1>

            <div className="tw-bg-gray-200 tw-rounded-3xl px-2 tw-shadow-inner tw-w-full">
            <h2 className="tw-text-2xl lg:tw-text-2xl tw-text-center tw-mt-12 tw-mb-4 tw-text-gray-800">{qs.q2text[props.lang]}</h2>  

            <div className="tw-flex tw-flex-col md:tw-flex-row tw-pt-4 tw-flex-wrap tw-w-full tw-items-start tw-justify-start md:tw-justify-center">

              {qs.q2answers ? qs.q2answers.map((x,i)=><>

                <div className="tw-w-full md:tw-w-1/2 lg:tw-w-1/3 tw-p-2 tw-cursor-pointer" onClick={()=>{qs.q2type == "multiple" ? updateq1Form(qs.q2answers, x.name, results[x.name] ? 0 : 1, "q1ALL", "q1NONE") : updateq2Form(qs.q2answers, x.name, results[x.name] ? 0 : 1)}}>
                <div style={{borderTopWidth:4, borderBottomWidth:4, borderLeftWidth:4, borderRightWidth:4}} className={results[x.name] ? "tw-font-bold tw-w-full tw-rounded-2xl tw-flex tw-flex-row tw-justify-center tw-items-center tw-bg-green-200 tw-text-lg tw-border-green-500 tw-shadow-inner tw-px-2 tw-py-8 tw-text-center" : "tw-w-full tw-bg-white tw-rounded-2xl tw-flex tw-flex-row tw-justify-center tw-items-center tw-text-lg tw-shadow-lg tw-border-yellow-500 hover:tw-shadow-inner tw-px-2 tw-py-8 tw-text-center"}>
                {x.answer[props.lang]}
                </div>
                </div>
              
              </>) : null}

              <div className="tw-w-full tw-flex tw-flex-row tw-justify-between tw-px-3 tw-pb-5 tw-pt-8">
              {qNum > 1 ? <button style={{backgroundColor:"#ebebeb", borderRadius:"1rem"}} type="button" className="btn font-weight-bold tw-text-xl tw-py-3 tw-px-4 tw-shadow-lg hover:tw-shadow-inner" onClick={()=>{setQNum(1)}}>{qs.q2buttonback ? qs.q2buttonback[props.lang] : "Back"}</button> : <div></div> }
              <button style={{backgroundColor:"#f89521", borderRadius:"1rem"}} type="button" className="btn font-weight-bold tw-text-xl tw-py-3 tw-px-4 tw-shadow-lg hover:tw-shadow-inner" onClick={()=>{setQNum(3)}}>{qs.q2button[props.lang]}</button>
              </div>

            </div>
            </div>

          </> : 

          qNum == 3 ? <>

            <h1 className="tw-text-2xl lg:tw-text-4xl tw-font-bold tw-text-center tw-my-8 tw-w-full" style={{
              borderBottom: "1px solid #ebebeb"}}>{qs.heading[props.lang]} <span className="tw-text-yellow-500">{qs.headingOrange[props.lang]}</span></h1>

            <div className="tw-bg-gray-200 tw-rounded-3xl px-2 tw-shadow-inner tw-w-full">
            <h2 className="tw-text-2xl lg:tw-text-2xl tw-text-center tw-mt-12 tw-mb-4 tw-text-gray-800">{qs.q3text[props.lang]}</h2>  

            <div className="tw-flex tw-flex-col md:tw-flex-row tw-pt-4 tw-flex-wrap tw-w-full tw-items-start tw-justify-start md:tw-justify-center">

              {qs.q3answers ? qs.q3answers.map((x,i)=><>

                <div className="tw-w-full md:tw-w-1/2 lg:tw-w-1/3 tw-p-2 tw-cursor-pointer" onClick={()=>{qs.q3type == "multiple" ? updateq1Form(qs.q3answers, x.name, results[x.name] ? 0 : 1, "q1ALL", "q1NONE") : updateq2Form(qs.q3answers, x.name, results[x.name] ? 0 : 1)}}>
                <div style={{borderTopWidth:4, borderBottomWidth:4, borderLeftWidth:4, borderRightWidth:4}} className={results[x.name] ? "tw-font-bold tw-w-full tw-rounded-2xl tw-flex tw-flex-row tw-justify-center tw-items-center tw-bg-green-200 tw-text-lg tw-border-green-500 tw-shadow-inner tw-px-2 tw-py-8  tw-text-center" : "tw-w-full tw-bg-white tw-rounded-2xl tw-flex tw-flex-row tw-justify-center tw-items-center tw-text-lg tw-shadow-lg tw-border-yellow-500 hover:tw-shadow-inner tw-px-2 tw-py-8  tw-text-center"}>
                {x.answer[props.lang]}
                </div>
                </div>
              
              </>) : null}

              <div className="tw-w-full tw-flex tw-flex-row tw-justify-between tw-px-3 tw-pb-5 tw-pt-8">
              {qNum > 1 ? <button style={{backgroundColor:"#ebebeb", borderRadius:"1rem"}} type="button" className="btn font-weight-bold tw-text-xl tw-py-3 tw-px-4 tw-shadow-lg hover:tw-shadow-inner" onClick={()=>{setQNum(2)}}>{qs.q3buttonback ? qs.q3buttonback[props.lang] : "Back"}</button> : <div></div> }
              <button style={{backgroundColor:"#f89521", borderRadius:"1rem"}} type="button" className="btn font-weight-bold tw-text-xl tw-py-3 tw-px-4 tw-shadow-lg hover:tw-shadow-inner" onClick={()=>{sendResults()}}>{qs.q3button[props.lang]}</button>
              </div>

            </div>
            </div>

          </> : 
          
          qNum == 4 ? 

          <>

            {success ? <><h1 className="tw-text-2xl lg:tw-text-4xl tw-font-bold tw-text-center tw-my-8 tw-w-full" style={{
              borderBottom: "1px solid #ebebeb"}}>{qs.heading[props.lang]} <span className="tw-text-yellow-500">{qs.headingOrange[props.lang]}</span></h1>

            <div className="tw-bg-gray-200 tw-rounded-3xl px-2 tw-shadow-inner tw-w-full">
            <h1 className="tw-text-2xl lg:tw-text-2xl tw-text-center tw-py-12 tw-text-gray-800">{qs.q4text[props.lang]}</h1>
            </div></> : 

            <><h1 className="tw-text-2xl lg:tw-text-4xl tw-font-bold tw-text-center tw-my-8 tw-w-full" style={{
              borderBottom: "1px solid #ebebeb"}}>{qs.failureheading[props.lang]} <span className="tw-text-yellow-500">{qs.failureheadingOrange[props.lang]}</span></h1>

            <div className="tw-bg-gray-200 tw-rounded-3xl px-2 tw-shadow-inner tw-w-full">
            <h1 className="tw-text-2xl lg:tw-text-2xl tw-text-center tw-py-12 tw-text-gray-800">{qs.failureq4text[props.lang]}</h1>
            </div></>}
            </> : 

            <><h1 className="tw-text-2xl lg:tw-text-4xl tw-font-bold tw-text-center tw-my-8 tw-w-full" style={{
              borderBottom: "1px solid #ebebeb"}}>{qs.sorryheading[props.lang]} <span className="tw-text-yellow-500">{qs.sorryheadingOrange[props.lang]}</span></h1>

            <div className="tw-bg-gray-200 tw-rounded-3xl px-2 tw-shadow-inner tw-w-full">
            <h1 className="tw-text-2xl lg:tw-text-2xl tw-text-center tw-py-12 tw-text-gray-800">{qs.sorrytext[props.lang]}</h1>
            </div></>

        }

          
        </div>
      </div>
      </Modal>
      </>
    )
}

export default Questions