import {Point, Quote} from '../Misc';

function WhyUs(props) {

    return (
        <>
        <Point text={{
          'en':"Knowledge of a luminary",
          'ru':'На плечах гигантов',
          'de':'Mit dem Wissen einer Koryphäe'}[props.lang]} />
        <Quote text={{
          'en':"Blackfort offers you an opportunity to invest your money based on the knowledge of a luminary in finance. Harry Markowitz, 1990 Nobel Memorial Prize in Economic Sciences, revolutionized investing by inventing the modern portfolio management. Markowitz’s portfolio theory replaces investments in individual stocks (stock picking) by investments in large and well-diversified portfolios. S&P 500 index owes its popularity to the Markowitz’s ideas ever since late 1950s. There are over 500 world-leading stocks combined so that the stock-specific risk of individual stocks is diversified away. Market leading indices such as the S&P 500 offer investors access to broadly diversified portfolios.",
          'ru':"Blackfort позволяет Вам инвестировать свой капитал мудро. Гарри Марковиц, Нобелевский лауреат 1990 года, произвел революцию в области инвестирования и изобрел портфельное управление. Его мудрость заключалась в использовании диверсифицированных портфелей и индексов. Инвестирование в индексы - самое лучшее, что Вы можете сделать на рынке акций. В индексе более 500 ведущих мировых компаний, объединенных таким образом, что все риски отдельных акций диверсифицированы.",
          'de':"Blackfort gibt Ihnen die Möglichkeit, Ihr Geld auf Basis des Wissens einer Koryphäe der Finanzwissenschaft anzulegen. Harry Markowitz, Nobelpreisträger der Wirtschaftswissenschaften, hat das Investieren grundlegend revolutioniert. Mit der Portfoliotheorie von Markowitz wird anstatt in einzelne Aktien (Stock Picking), in ein breit diversifizierte Portfolios investiert. Dadurch kann das titelspezifische Risiko einzelner Aktien wegdiversifiziert werden. Marktführende Indizes wie der S&P 500 bieten Anlegern Zugang zu solch breit diversifzierten Portfolios."}[props.lang]} />

        <Point text={{
          'en':"And beyond",
          'ru':"Более того,",
          'de':"...und ein bisschen mehr"}[props.lang]} />
        <Quote text={{
          'en':"At Blackfort we go one step further: We offer you the opportunity to optimize your investment in the S&P 500 broadly diversified index through active and systematic management. Blackfort Daily Quant Accelerator I offers active and rule-based trading robot on top of the S&P 500 index investment, and we are committed to continuously improving this robot.",
          'ru':"В Blackfort AG мы предлагаем Вам уникальную возможность защитить Ваш капитал путем активного управления Вашими индексными инвестициями S&P 500. При этом Вашими вложениями управляет робот, а мы создаем все условия, чтобы робот выполнял свою работу наилучшим образом. Ежедневный доходный ускоритель Blackfort I - это активная роботизированная торговля в S&P 500, которая дает Вам дополнительные преимущества индексных инвестиций, потому что робот торгует индексом разумно.",
          'de':"Bei Blackfort gehen wir noch einen Schritt weiter: Wir bieten Ihnen die Möglichkeit Ihre Investition in den S&P 500 durch ein aktives und zugleich systematisches Management weiter zu optimieren. Blackfort Daily Quant Accelerator I bietet aktives und roboterbasiertes Trading im S&P 500. Wir geben unsererseits alles dafür, diesen Roboter immer weiter zu verbessern."}[props.lang]} />

        <Point text={{
          'en':"Why S&P 500?",
          'ru':"Почему индекс S&P 500?",
          'de':"Warum der S&P 500?"}[props.lang]} />
        <Quote text={{
          'en':"Why an index? For many years asset managers tried to predict future developments of stocks and make a selection for investing (stock picking) on this basis. However, more than 70 years of financial studies have shown that in most cases, such a conventional, active management does not yield anything - except fees. And that is precisely why passive investment in a broadly diversified index is the superior and more efficient approach.",
          'ru':"Почему индекс? В былые времена Вы бы наняли управляющего, который делал бы для Вас \"подборку акций\" на основе индивидуальных торговых сигналов и предсказаний. Но более 70 лет исследований в области финансов доказывают, что такие инвестиции неэффективны. Инвестируя в индекс, Вы платите комиссию только за диверсификацию и избавляетесь от значительной доли риска. Именно потому пассивное инвестирование в индекс - это самое эффективное инвестирование в акции.",
          'de':"Wieso ein Index? Lange Zeit wurde versucht zukünftige Entwicklungen von Aktien vorherzusagen und auf dieser Basis eine Selektion (Stock Picking) vorzunehmen. Über 70 Jahre an finanzwissenschaftlichen Untersuchungen zeigen aber: Ein solch herkömmliches, aktives Management bringt in den meisten Fällen nichts – ausser Gebühren. Und genau deshalb ist passives Investieren in einen breit diversifizierteren Index der überlegene und effizientere Ansatz."}[props.lang]} />

        <Point text={{
          'en':"Why robot?",
          'ru':"Почему роботы?",
          'de':"Warum ein Roboter?"}[props.lang]} />
        <Quote text={{
          'en':"At Blackfort we believe in technological progress. Robots open up new opportunities for us - even when investing. Indices like the S&P 500 are highly liquid. A large number of investors are active in the S&P 500 every day. This causes numerous market movements. A robot is able to exploit these movements in a targeted and systematic way. A robot does not panic when human market participants panic - on the contrary, it keeps a cool head in every market situation.",
          'ru':"В компании Blackfort AG мы увлекаемся современными технологиями. В S&P 500 инвестирует множество людей, активны миллионы инвесторов. Как в океане, в этом океане инвесторов тоже есть волны, которые профессионалы называют микроструктурой рынка. Современные роботизированные технологии могут извлечь дополнительную выгоду, используя информацию об этих волнах должным образом. Это то, что делает наша ежедневная квантовая стратегия.",
          'de':"Bei Blackfort sind wir überzeugt vom technologischen Fortschritt. Roboter eröffnen uns neue Möglichkeiten – auch beim Investieren. Indizes wie der S&P 500 sind hochliquide. Eine grosse Anzahl von Investoren ist täglich im S&P 500 aktiv. Dies verursacht zahlreiche Marktbewegungen. Ein Roboter ist in der Lage diese Bewegungen gezielt und systematisch auszunutzen. Ein Roboter verfällt nicht in Panik, wenn menschliche Markteilnehmer in Panik verfallen – vielmehr behält er in jeder Marktlage einen kühlen Kopf."}[props.lang]} />

        <Point text={{
          'en':"Why Blackfort Daily Quant Accelerator I?",
          'ru':"Почему Ежедневный доходный ускоритель Blackfort I?",
          'de':"Warum Blackfort Daily Quant Accelerator I?"}[props.lang]} />
        <Quote text={{
          'en':"Blackfort Daily Quant Accelerator I combines breakthrough technology with market-leading expertise and experience in asset management. The robot's code is proven. Each trade of the robot includes only a small amount. Positions are closed within very short periods of less than one trading day. The risk of loss is under control and remains very limited even in very volatile market phases. The result: In 2020, the S&P 500 generated an annual return of 17.9%. With the Blackfort Daily Quant Accelerator I, this return increases to 27.6%. This corresponds to an additional return of 9.7% generated by our robotic trading.",
          'ru':"Этот инструмент объединяет в себе современные разработки в области инвестиционных технологий и. наш многолетний опыт управления активами. Сделки робота - это небольшие суммы, позиции закрываются в короткие промежутки времени, менее чем за один торговый день. Ваш риск в самых худших сценариях строго ограничен. Ваш выигрыш - выше, чем у диверсифицированного и ликвидного индекса. Таким образом мы предлагаем Вам лучшее, что есть в мире инвестиций.",
          'de':"Blackfort Daily Quant Accelerator I verknüpft bahnbrechende Technologie mit marktführender Expertise und Erfahrung im Asset Management. Der Code des Roboters ist bewährt. Jeder Trade des Roboters umfasst lediglich einen kleinen Betrag. Positionen werden innerhalb von sehr kurzen Zeitperioden von weniger als einem Handelstag wieder geschlossen. Das Verlustrisiko bleibt auch in sehr volatilen Marktphasen stark begrenzt. Das Resultat: Im Jahr 2020 hat der S&P 500 eine Jahresrendite von 17.9% erwirtschaftet. Mit dem Blackfort Daily Quant Accelerator I konnte diese Rendite auf 27.6% erhöht werden. Dies entspricht einer Mehrrendite von 9.7% - erwirtschaftet durch robotisiertes, systematisches Trading."}[props.lang]} />

        </>
    );
  }
  
export default WhyUs;