import {Point, Quote} from '../Misc';

function Disclosures(props) {

    return (
        <>
        <Point text={{
          'en':"DISCLAIMER for clients",
          'ru':'Наши юридические комментарии клиентам',
          'de':'HAFTUNGSAUSSCHLUSS für Kunden'}
          [props.lang]} />

        <Quote text={{
          'en':<>Blackfort Capital AG is the developer of the investment strategy Blackfort Daily Quant Accelerator I ISIN CH0505798675. iMaps ETI AG, an issuer of AMCs registered in Liechtenstein, has issued AMCs based on the Blackfort investment strategy under its approved issuance program. Information on the issuance program and prospectus can be downloaded free of charge at the <a href="https://imaps-capital.com/etis/blackfort-daily-quant-accelerator-i-eti/" className="tw-text-yellow-500 hover:tw-text-yellow-500">issuer's website</a>.</>,
          'ru':<>Blackfort Capital AG является разработчиком инвестиционной стратегии Ежедневный доходный ускоритель Блекфорт I (ISIN CH0505798675). Компания iMaps ETI AG, зарегистрированная в Лихтенштейне, является эмитентом торгуемого финансового инструмента, основанного на инвестиционной стратегии компании Blackfort Capital AG. Вы можете найти всю информацию о выпуске, а также инвестиционный проспект, на <a href="https://imaps-capital.com/etis/blackfort-daily-quant-accelerator-i-eti/" className="tw-text-yellow-500 hover:tw-text-yellow-500">странице эмитента</a>.</>,
          'de':<>Blackfort Capital AG ist Entwickler der Handelsstrategie Blackfort Daily Quant Accelerator I ISIN CH0505798675. iMaps ETI AG, ein Herausgeber von AMCs in Liechtenstein registriert, hat AMCs basierend auf der Blackfort Handelsstrategie herausgegeben, durch Blackfort genehmigt. Der Prospekt ist kostenlos einsehbar auf der <a href="https://imaps-capital.com/etis/blackfort-daily-quant-accelerator-i-eti/" className="tw-text-yellow-500 hover:tw-text-yellow-500">Website des Emittenten</a>.</>}
          [props.lang]} />

        <Quote text={{
          'en':"This prospectus does not constitute an individual investment recommendation. Financial instruments indicated therein may not be suitable for the respective client. All prices and figures are indicative and do not represent a firm offer. The ETI Securities are not principal protected and are a high-risk investment in the form of a debt instrument. The ETI Security holders are neither assured of repayment of the capital invested nor are they assured of payment of any return on such capital.",
          'ru':"Настоящий сайт, а также инвестиционный проспект, не являются инвестиционной рекомендацией. Указанные здесь финансовые инструменты могут не подходить клиенту. Все цены, цифры и заявления являются ориентировочными, предоставлены в целях информирования клиента, и не являются обещанием или гарантией. Инвестиции в Ежедневный доходный ускоритель Блекфорт I являются высоко рисковыми и не застрахованы от возможных потерь капитала или падения доходности в будущем.",
          'de':"Der Prospekt stellt keine individuelle Anlageempfehlung dar. Die darin angegebenen Finanzinstrumente sind möglicherweise für den spezifischen Kunden nicht geeignet. Alle Preise und Daten sind indikativ und stellen kein verbindliches Angebot dar. Die ETI-Wertpapiere sind nicht kapitalgeschützt und stellen eine riskante Investition in Form eines Schuldtitels dar. Den Inhabern von ETI-Wertpapieren ist weder die Rückzahlung des investierten Kapitals noch die Zahlung einer Rendite auf dieses Kapital zugesichert."}
          [props.lang]} mtop="0" ptop="4" />

        <Quote text={{
          'en':"Blackfort Capital may not be responsible for the financial or any other circumstances following the subscription or acquisition of the products in discussion. Investors should perform their own research on the risks involved in and consult their personal advisors if necessary. Mentioned projections and forward-looking statements are based on the past performance and are subject to significant business, economic and competitive uncertainties beyond control of Blackfort Capital AG.",
          'ru':"Blackfort Capital AG не несет ответственность за финансовые или любые другие обстоятельства, возникшие после приобретения финансовых продуктов. Инвесторы должны сами оценить риски и при необходимости получить независимую консультацию. Упомянутые прогнозы основаны на прошлых финансовых результатах, в то время как будущая доходность подвержена существенной деловой, экономической и конкурентной неопределенности, не зависящей от Blackfort Capital AG.",
          'de':"Blackfort Capital AG ist nicht für die finanziellen oder sonstige Entwicklungen nach der Zeichnung oder dem Erwerb der besprochenen Produkte haftbar. Anleger sollten ihre eigenen Abklärungen über die damit verbundenen Risiken durchführen und gegebenenfalls ihre persönlichen Berater konsultieren. Prognosen und zukunftsgerichtete Aussagen basieren auf der historischen Performance und unterliegen erheblichen geschäftlichen, wirtschaftlichen und wettbewerbsbezogenen Unsicherheiten, die außerhalb der Kontrolle der Blackfort Capital AG liegen."}[props.lang]} mtop="0" ptop="4" />

        </>
    );
  }
  
export default Disclosures;